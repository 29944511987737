import striptags from 'striptags';
class Utility {
  /**
   * Normalize URL by normalizing spaces and case
   * @param {string} name
   * @return {string}
   */
  static normalizeUrl(name) {
    return this.normalizeCase(this.normalizeSpaces(name));
  }

  static normalizeFilter(name) {
    if (!name) {
      return null;
    }
    return this.normalizeUrl(name);
  }

  /**
   * Replace spaces and underscores with hyphens
   * @param {string} name
   * @return {string}
   */
  static normalizeSpaces(name) {
    if (!name) {
      return '';
    }
    return name.trim().replace(/_/g, '-').replace(/\s+/g, '-');
  }

  /**
   * Convert string to lowercase
   * @param {string} name
   * @return {string}
   */
  static normalizeCase(name) {
    return name.toLowerCase();
  }

  static prepareLike = (name) => {
    if (!name) {
      return null;
    }
    return name.replaceAll('-', '_');
  };

  static scomposeURL(url) {
    // Remove all parameters from the URL
    url = url.split('?')[0];

    // Function to make names good looking
    function formatName(name) {
      let decodedName = decodeURIComponent(name);
      decodedName = decodedName.replace(/[_-]/g, ' ').toLowerCase();
      return decodedName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    // Check if the URL is just "/"
    if (url === '/') {
      return { segments: [], result: { lang: 'en' } };
    }

    // Split the URL into segments
    const segments = url.split('/').filter((segment) => segment !== '');

    let result = { lang: 'en' };
    let parsedSegments = [];
    let currentPath = '';

    // Check if the first segment is a language code
    if (segments[0].length === 2 && /^[a-z]{2}$/.test(segments[0])) {
      result.lang = segments[0].toLowerCase();
      segments.shift();
    }

    // Handle special cases for 's' paths
    if (segments[0] === 's') {
      result.s = segments[1];
      parsedSegments.push({
        name: segments[1],
        displayName: formatName(segments[1]),
        link: `/${result.lang}/s/${segments[1]}`,
      });

      if (segments.length >= 4 && ['c', 'i', 't'].includes(segments[2])) {
        result[segments[2]] = segments[3];
        parsedSegments.push({
          name: segments[3],
          displayName: formatName(segments[3]),
          link: `/${result.lang}/s/${segments[1]}/${segments[2]}/${segments[3]}`,
        });
      }
    } else {
      // For all other pages
      result.page = segments.join('/');
      segments.forEach((segment, index) => {
        currentPath += `/${segment}`;
        parsedSegments.push({
          name: segment,
          displayName: formatName(segment),
          link: `/${result.lang}${currentPath}`,
        });
      });
    }

    return { segments: parsedSegments, result };
  }

  static testSComposeURL() {
    const testCases = [
      { url: '/', expected: { lang: 'en' } },
      { url: '/it', expected: { lang: 'it', page: '' } },
      { url: '/about-us', expected: { lang: 'en', page: 'about-us' } },
      { url: '/it/about-us', expected: { lang: 'it', page: 'about-us' } },
      { url: '/en/products/category/item', expected: { lang: 'en', page: 'products/category/item' } },
      { url: '/fr/very/long/nested/path', expected: { lang: 'fr', page: 'very/long/nested/path' } },
      { url: '/s/section', expected: { lang: 'en', s: 'section' } },
      { url: '/de/s/sektion', expected: { lang: 'de', s: 'sektion' } },
      { url: '/s/products/c/electronics', expected: { lang: 'en', s: 'products', c: 'electronics' } },
      { url: '/es/s/productos/i/telefono', expected: { lang: 'es', s: 'productos', i: 'telefono' } },
      { url: '/s/blog/t/technology', expected: { lang: 'en', s: 'blog', t: 'technology' } },
      { url: '/it/s/notizie/c/sport/extra', expected: { lang: 'it', s: 'notizie', c: 'sport' } },
      {
        url: '/unusual-path/with/numbers/123/456',
        expected: { lang: 'en', page: 'unusual-path/with/numbers/123/456' },
      },
      { url: '/ru/s/новости', expected: { lang: 'ru', s: 'новости' } },
      { url: '/s/section/unexpected/segments', expected: { lang: 'en', s: 'section' } },
    ];

    let passCount = 0;
    let failCount = 0;

    testCases.forEach((testCase, index) => {
      const result = this.scomposeURL(testCase.url);

      console.log(`Test case ${index + 1}: ${testCase.url}`);
      console.log('Result:', result);
      console.log('Expected:', { segments: '[Array of segments]', result: testCase.expected });

      let passed = true;
      for (const key in testCase.expected) {
        if (result.result[key] !== testCase.expected[key]) {
          passed = false;
          console.log(`❌ Mismatch in '${key}': expected '${testCase.expected[key]}', got '${result.result[key]}'`);
        }
      }

      // Check if there are any unexpected keys in the result
      for (const key in result.result) {
        if (!(key in testCase.expected)) {
          passed = false;
          console.log(`❌ Unexpected key in result: '${key}' with value '${result.result[key]}'`);
        }
      }

      // Basic check for segments array
      if (!Array.isArray(result.segments)) {
        passed = false;
        console.log(`❌ Expected 'segments' to be an array, got ${typeof result.segments}`);
      }

      if (passed) {
        console.log('✅ Test passed');
        passCount++;
      } else {
        console.log('❌ Test failed');
        failCount++;
      }
      console.log('-'.repeat(40));
    });

    console.log(`Test summary: ${passCount} passed, ${failCount} failed`);
    console.log(`Total test cases: ${testCases.length}`);
  }

  static capitalizeAllWords(string) {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
   * Get excerpt from text.
   *
   * @param {string} text - The text to get an excerpt from.
   * @param {number} [words=30] - The number of words to limit the excerpt to.
   * @param {string} [readmore='....'] - The string to append if the text exceeds the word limit.
   * @returns {string} The excerpt.
   */
  static getExcerpt(text, words = 30, readmore = '....') {
    // Strip HTML tags and replace newlines and carriage returns with spaces
    const taglessDescription = striptags(text)
      .replace(/[\r\n]+/g, ' ')
      .replace(/\s\s+/g, ' ');

    // Split the text into words
    const wordArray = taglessDescription.split(' ');

    // If the text has more words than the limit, truncate and add the readmore string
    if (wordArray.length > words) {
      return wordArray.slice(0, words).join(' ') + readmore;
    }

    // Otherwise, return the text as is
    return taglessDescription;
  }

  static matchLanguageISOCode(ISOCode) {
    if (!ISOCode) {
      return null;
    }
    //Match the iso code of the language key with the language name
    const languages = {
      en: 'English',
      es: 'Español',
      fr: 'Français',
      de: 'Deutsch',
      it: 'Italiano',
      pt: 'Português',
      zh: '中文',
      ar: 'العربية',
      ru: 'Русский',
      ja: '日本語',
      ko: '한국어',
      hi: 'हिन्दी',
      id: 'Bahasa Indonesia',
      ta: 'தமிழ்',
      te: 'తెలుగు',
      bn: 'বাংলা',
      pa: 'ਪੰਜਾਬੀ',
      ur: 'اردو',
      fa: 'فارسی',
      vi: 'Tiếng Việt',
      tr: 'Türkçe',
      el: 'Ελληνικά',
      th: 'ไทย',
      uk: 'Українська',
      hy: 'Հայերեն',
      ka: 'ქართული',
      gu: 'ગુજરાતી',
      kn: 'ಕನ್ನಡ',
      ml: 'മലയാളം',
      mr: 'मराठी',
      ro: 'Română',
      nl: 'Nederlands',
      hu: 'Magyar',
      sv: 'Svenska',
      no: 'Norsk',
      fi: 'Suomi',
      da: 'Dansk',
      cs: 'Čeština',
      pl: 'Polski',
      he: 'עברית',
      sr: 'Српски',
      sk: 'Slovenčina',
      hr: 'Hrvatski',
      bg: 'Български',
      sl: 'Slovenščina',
      lv: 'Latviešu',
      lt: 'Lietuvių',
      et: 'Eesti',
      ms: 'Bahasa Melayu',
      af: 'Afrikaans',
      sw: 'Kiswahili',
      tl: 'Tagalog',
      mk: 'Македонски',
      bs: 'Bosanski',
      is: 'Íslenska',
      mt: 'Malti',
      ga: 'Gaeilge',
      cy: 'Cymraeg',
      gl: 'Galego',
      eu: 'Euskara',
      sq: 'Shqip',
      mn: 'Монгол',
      am: 'አማርኛ',
      ne: 'नेपाली',
      my: 'မြန်မာစာ',
      km: 'ខ្មែរ',
      si: 'සිංහල',
      lo: 'ລາວ',
      uz: 'Oʻzbekcha',
      ky: 'Кыргызча',
      tg: 'Тоҷикӣ',
      tt: 'Татарча',
      kk: 'Қазақша',
      tk: 'Türkmençe',
      az: 'Azərbaycan',
      ps: 'پښتو',
      dv: 'ދިވެހި',
      rw: 'Kinyarwanda',
      ha: 'Hausa',
      yo: 'Yorùbá',
      ig: 'Igbo',
      zu: 'IsiZulu',
      xh: 'IsiXhosa',
      st: 'Sesotho',
      sn: 'ChiShona',
      tn: 'Setswana',
      ts: 'Xitsonga',
      ve: 'Tshivenḓa',
      nr: 'IsiNdebele',
      ss: 'SiSwati',
      ln: 'Lingála',
      dk: 'Dansk',
      il: 'עברית',
      gb: 'English',
      cn: '中文',
      jp: '日本語',
      kr: '한국어',
      se: 'Svenska',
      ua: 'Українська',
      ir: 'فارسی',
      gr: 'Ελληνικά',
      in: 'हिन्दी',
      pk: 'اردو',
    };
    return languages[ISOCode.toLowerCase()];
  }

  static captureException(error) {
    const errorMsg = {
      message: error.message,
      stack: error.stack,
    };
    return errorMsg;
  }

  static async getUserConfirmation(txt) {
    const rl = readline.createInterface({
      input: process.stdin,
      output: process.stdout,
    });

    return new Promise((resolve) => {
      rl.question(txt, (answer) => {
        rl.close();
        resolve(answer.trim().toLowerCase() === 'yes' || answer.trim().toLowerCase() === 'y');
      });
    });
  }

  static bufferToBool(buffer) {
    // Check if input is a serialized buffer object
    if (buffer && buffer.type === 'Buffer' && Array.isArray(buffer.data)) {
      // Convert back to Buffer
      buffer = Buffer.from(buffer.data);
    } else if (!Buffer.isBuffer(buffer)) {
      throw new Error('Input is not a buffer');
    }

    // Check if the buffer is empty
    if (buffer.length === 0) return false;

    // Check for known boolean representations
    if (buffer[0] === 0) return false;
    if (buffer[0] === 1) return true;

    const str = buffer.toString().trim();

    // Handle boolean strings
    if (str.toLowerCase() === 'true') return true;
    if (str.toLowerCase() === 'false') return false;

    // Handle numeric values explicitly
    const num = Number(str);
    if (!isNaN(num)) return Boolean(num);

    // Fallback: non-empty strings are true, empty strings are false
    return Boolean(str);
  }

  static formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return t('{count} day', { count: days });
    } else if (hours > 0) {
      return t('{count} hour', { count: hours });
    } else {
      const minutes = Math.floor(seconds / 60);
      return t('{count} minute', { count: minutes });
    }
  }

  static formatHours(hours) {
    if (hours < 24) {
      return t('{count} hour', { count: hours });
    } else {
      const days = Math.floor(hours / 24);
      return t('{count} day', { count: days });
    }
  }
}

export const handleApiResponse = async (url, options) => {
  const { data, error } = await useFetch(url, {
    ...options,
    onRequest({ request, options }) {
      // Set the request headers if needed
    },
    onRequestError({ request, options, error }) {
      // Handle the request errors
      console.error('Request Error:', error);
    },
    onResponse({ request, response, options }) {
      // Extract the location header and handle redirects
      const locationHeader = response.headers.get('location');
      if (response.status === 302 && locationHeader) {
        navigateTo(locationHeader);
      }
    },
    onResponseError({ request, response, options }) {
      // Handle the response errors
      console.error('Response Error:', response.status, response.statusText);
    },
  });

  return { data, error };
};

export default Utility;
